import { defineStore } from 'pinia'
import { useRequest } from '@/composables/useRequest'
import { api } from '@/plugins/axios'
import { ref } from 'vue'


export const useCasinoStore = defineStore('casino', () => {

  // const { data: games, execute: fetchGames } = useRequest(
  //   'cx-casino-provider/games', { method: 'post' })
  //
  // const { data: detail, execute: play } = useRequest('cx-casino-provider/play',
  //   { method: 'post' })

  const providers = ref()
  const games = ref()
  const link = ref()

  async function lobbies() {
     providers.value = await api.get('v2/casino/lobbies')
  }

  async function fetchGames(vendor) {
    games.value = await api.get(`v2/casino/games?vendor=${vendor}`)
  }

  async function fetchLink(params) {
    let queryParams = new URLSearchParams(params).toString()
    link.value = await api.get(`v2/casino/link?${queryParams}`)
  }

  return {
    // detail,
    // play: async (data) => await play({ data }),
    // games,
    // fetchGames: async (data) => await fetchGames({ data }),
    providers,
    lobbies,
    games,
    fetchGames,
    link,
    fetchLink
  }
})

